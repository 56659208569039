a {
    color: unset;
    text-decoration: unset;
    cursor: pointer;
}

#pop-up {
    z-index: 1000;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    bottom: 50px;

    @media (min-width: 768px) {
        bottom: 30px;
    }

}

.pop-up {
    &-content {
        display: flex;
        background-color: #303030;
        color: #ffffff;
        border-radius: 2px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        flex-direction: column;
        max-width: 75%;
        position: relative;

        @media (min-width: 768px) {
            border-radius: 5px;
            flex-direction: row;


        }
    }

    &__link-arrow {
        position: absolute;
        right: 25px;
        bottom: 25px;
        transition: transform ease-in-out 0.2s;
    }

    &__text {
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 40px);
        max-width: 300px;

        h1 {
            font-size: 24px;
            text-align: left;
        }

        span,
        p,
        div {
            text-align: left;
        }

    }

    &__link {
        color: unset;
        text-decoration: unset;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 20px;

        &:hover {
            .pop-up__link-arrow {
                transform: translateX(5px);

            }
        }
    }

    &__tag {
        color: #7f95bf;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 1.625;
        font-weight: 500;
        font-family: "Barlow Medium";
        margin-bottom: 5px;
    }

    &__title {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.2;
        font-family: "Barlow SemiBold";
    }

    &__excerpt {
        font-size: 12px;
        color: #858585;
        line-height: 1.5;

    }

    &__image {
        img {
            display: block;
            max-width: 500px;
            width: 100%;
        }
    }
}

.button {
    border: 2px solid white;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    margin-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-decoration: none;

    &:hover {
        background: white;
        color: #303030;
    }
}