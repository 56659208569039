.map-wrap {
    position: relative;
    width: 100%;
    height: 100vh;

    .map {
        position: absolute;
        width: 100%;
        height: 100%;

        @media (min-width: 768px) {
            height: 100%;
        }
    }
}